import React from "react"
import Button from '@material-ui/core/Button';
import IconImage from "./icon-image";

class Start extends React.Component {

  render() {
    return (
      <div className="start" style={{
        height: `100vh`,
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
        <div style={{
          width: `10rem`,
          height: `10rem`,
          marginBottom: `1em`
        }}>
          <IconImage></IconImage>
        </div>
        <Button variant="contained" color="primary" href="https://app.daily-memories.com">
          Start Writing
        </Button>
      </div>
    )
  }
}

export default Start