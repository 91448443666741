import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import IconSvg from "./icon-svg"

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

const Jumbotron = () => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div className="jumbotron" style={{
      background: `linear-gradient(to left bottom, #b3fbff 0%, #ffc6b3 100%)`,
      height: `100vh`,
    }}>
      <div style={{
        display: 'flex',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        padding: '30px 0',
      }}>
        <IconSvg></IconSvg>
        <h1 style={{
          fontSize: '3rem'
        }}>
          {data.site.siteMetadata.title}
        </h1>
        <h4>Journaling made easy</h4>
      </div>
    </div >
  )
}

export default Jumbotron

