import React from "react"
import "./demo.css"

// pixel 2XL dimensions
const SCREEN_HEIGHT = 823;
const SCREEN_WIDTH = 411;
const SCREEN_BORDER_RADIUS = 25;
const SCREEN_NAV_BAR_HEIGHT = 15;
const SCREEN_INFO_BAR_HEIGHT = 20;
const PHONE_TOP_BEZEL = 60;
const PHONE_SIDE_BEZEL = 13;
const PHONE_BOTTOM_BEZEL = 80;
const PHONE_BORDER_RADIUS = 50;
const PHONE_WIDTH = SCREEN_WIDTH + 2 * PHONE_SIDE_BEZEL;
const PHONE_HEIGHT = SCREEN_HEIGHT + PHONE_BOTTOM_BEZEL + PHONE_TOP_BEZEL;


class Demo extends React.Component {
  state = { screenScale: 0, windowHeight: 0, windowWidth:0 }
  componentDidMount() {
    const maxScreenHeight = window.innerHeight * (window.innerWidth > 600 ? .6 : .75);
    const maxScreenWidth = window.innerWidth * (window.innerWidth > 600 ? .3 : .75);
    // use the smallest dimension restriction
    this.setState({
      screenScale: (maxScreenHeight / SCREEN_HEIGHT) < (maxScreenWidth / SCREEN_WIDTH)
        ? maxScreenHeight / SCREEN_HEIGHT
        : maxScreenWidth / SCREEN_WIDTH,
        windowHeight: window.innerHeight,
        windowWidth: window.innerWidth
    })
  }


  render() {
    return (
      <div class="demo-container" style={{
        height: `${this.state.windowHeight}px`,
      }}>
        {/* TODO: fix vertical scrolling problem */}
        <div className="phone sticky" style={{
          borderRadius: `${PHONE_BORDER_RADIUS}px`,
          height: `${PHONE_HEIGHT}px`,
          zIndex: 5,
          overflow: 'hidden',
          transform: `scale(${this.state.screenScale})`,
          width: `${PHONE_WIDTH}px`,
          paddingTop: `${PHONE_TOP_BEZEL}px`,
          paddingBottom: `${PHONE_BOTTOM_BEZEL}px`,
          paddingLeft: `${PHONE_SIDE_BEZEL}px`,
          paddingRight: `${PHONE_SIDE_BEZEL}px`,
          boxShadow: '0 8px 6px -6px black',
          background: 'black',
          position: 'sticky',
          top: '0%', // hack, please replace
        }}>
          <div className="screen" style={{
          }}>
            <div className="infoBar" style={{
              background: '#b3fbff',
              borderTopLeftRadius: `${SCREEN_BORDER_RADIUS}px`,
              borderTopRightRadius: `${SCREEN_BORDER_RADIUS}px`,
              height: `${SCREEN_INFO_BAR_HEIGHT}px`
            }}></div>

            <iframe id="theFrame"
              title="demo"
              src="https://app.daily-memories.com/demo"
              style={{
                height: `${SCREEN_HEIGHT - SCREEN_NAV_BAR_HEIGHT - SCREEN_INFO_BAR_HEIGHT}px`,
                width: `${SCREEN_WIDTH}px`,
                transformOrigin: '0 0',
                marginBottom: 'unset'
              }}
              frameBorder="0"></iframe>
          </div>
        </div>
      </div>
    )
  }
}

export default Demo