import React from "react"

import SEO from "../components/seo"
import Jumbotron from "../components/jumbotron"
import Demo from "../components/demo"
import './index.css';
import Start from "../components/start";
// import Entry from "../components/entry";
// import mockEntries from "../components/mockEntries";

const IndexPage = () => {
  // const entries = mockEntries.map(({ title, imageUrls, text }) =>
  // (<Entry key={title} title={title} imageUrls={imageUrls} text={text}></Entry>));
  return (
    <div>
      <SEO title="Daily" description="Journaling made easy" />
      <Jumbotron></Jumbotron>
      {/* {entries} */}
      <div className="content-container">
        <Demo></Demo>
        <Start></Start>
      </div>
    </div>
  )
}

export default IndexPage